/* eslint-disable no-param-reassign */
/* eslint-env browser */
import ready from '../components/ready';
import {
  setResponsiveNav,
  setResponsiveToggle, setCopyToClipboard,
} from '../components/nav';
import copyHexCode from '../components/copy-hex-code';
import { isElementWithClassNameInEventPath, setMetaThemeColor, setHorizontalScroll } from '../components/helpers';
import { setResponsiveTable, runDemotableButtons, changeBackgroundColor } from '../components/table';
import { setTabs } from '../components/tabs';
import { setContentToggle } from '../components/content-toggle';
import {
  closeAllContentTogglesInToolbar,
  contentToggleEdgeHandling,
  findToolbar,
  lockBodyScroll,
  unlockBodyScroll,
} from '../components/toolbar';
import { horizontalScrollButtonNext, horizontalScrollButtonPrevious } from '../components/horizontal-scroll-btn';
import { setupAutoExpand } from '../components/text-area';
import { charCounter } from '../components/char-counter';
import { dropdownIconToggle } from '../components/form';
import { stepperLabelsCenter } from '../components/stepper';

let windowInnerWidth = 0;

ready(() => {
  const tabbedContent = document.querySelectorAll('.tabbed-content');
  const tabbedObjects: ITabbedObject[] = [];
  tabbedContent.forEach((tab) => tabbedObjects.push({
    domElement: tab,
    rendered: false,
  }));
  setTabs(tabbedObjects);
  setMetaThemeColor();

  const navElements = document.querySelectorAll('.nav:not(.nav--vertical)');
  navElements.forEach((n) => setResponsiveNav(navElements, n));
  setResponsiveToggle(navElements);

  const tables = document.querySelectorAll('.table--wrap-medium-width, .table--wrap-default-width');
  tables.forEach((table) => setResponsiveTable(table));

  // Attention highlight style for table rows
  const rows = document.querySelectorAll('.table__row--flash-attention, .table__row--flash-confirm, .table__row--flash-warning') as NodeListOf<HTMLTableRowElement>;
  changeBackgroundColor(rows);

  // Demo buttons to demonstrate attention, confirm and warning styles
  const demoTableButtons = document.querySelectorAll('#demo-button--attention, #demo-button--confirm, #demo-button--warning');
  runDemotableButtons(demoTableButtons, rows);

  // Progress bar animation
  const animationButtons = document.querySelectorAll('.button');
  const animatedProgressBars = document.querySelectorAll('.progress-bar__progress.progress-bar__progress--confirm.progress-bar__progress--transistion-from-start');
  animationButtons.forEach((button) => {
    button.addEventListener('click', () => {
      animatedProgressBars.forEach((progressBar) => {
        progressBar.classList.toggle('progress-bar__progress--transistion-from-start');
      });
    });
  });

  // Character counter for form input
  const elements = document.querySelectorAll('.form__field__character-count');
  elements.forEach((element) => {
    charCounter(element as HTMLElement);
  });

  // Textarea input
  const textarea = document.getElementById("autoexpand") as HTMLTextAreaElement | null;
  setupAutoExpand(textarea);
  window.onload = function () {
    setupAutoExpand(textarea);
  };

  // Dropdown box icon toggle
  // const dropDownElements: NodeListOf<HTMLElement> = document.querySelectorAll('.form__field select, .form__field__combo-box__field');
  // dropDownElements.forEach((element) => {
  //   dropdownIconToggle(element);
  // });

  // Centers stepper labels when they are 4 chars or less
  const stepperLabels: NodeListOf<HTMLElement> = document.querySelectorAll('.stepper__item__label');
  stepperLabels.forEach((element) => {
    stepperLabelsCenter(element);
  });


  // Find alle copy-to-clipboard elementer
  const ctc = document.querySelectorAll('.copy-to-clipboard');
  ctc.forEach((element) => setCopyToClipboard(element as HTMLElement, document.querySelector('html')?.getAttribute('lang') === 'en' ? 'Hexcode copied!' : 'Hexkode kopieret!'));
  copyHexCode();

  //const toggles = document.querySelectorAll('.box .content-toggle__content, .profile-header .content-toggle__content, .design-documentation .content-toggle__content');
  const toggles = document.querySelectorAll('.content-toggle__content:not(.toolbar .content-toggle__content)');
  toggles.forEach((toggle) => {
    setContentToggle(toggle);
  });

  // Hortizontal scroll buttons
  const scrollButtonsNext = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-next');
  const scrollButtonsPrevious = document.querySelectorAll('.button.button--small.button--icon.button--icon--hide-label.icon-previous');
  horizontalScrollButtonNext(scrollButtonsNext);
  horizontalScrollButtonPrevious(scrollButtonsPrevious);

  const horizontalScroll = document.querySelectorAll('.horizontal-scroll');
  horizontalScroll.forEach((hs) => {
    setHorizontalScroll(hs as HTMLElement);
    window.addEventListener('resize', () => {
      setHorizontalScroll(hs as HTMLElement);
    });
  });

  const switchSegments = document.querySelectorAll('.switch__segment');
  switchSegments.forEach((switchSegment) => {
    switchSegment.addEventListener('click', () => {
      const horizontalScrollTabbed = document.querySelectorAll('.horizontal-scroll');
      horizontalScrollTabbed.forEach((hsTabbed) => {
        setHorizontalScroll(hsTabbed as HTMLElement);
      });
    });
  });

  // Kursuskatalog
  const togglesInToolbars = document.querySelectorAll('.toolbar .content-toggle__content');
  togglesInToolbars.forEach((toggle) => {
    setContentToggle(
      toggle,
      (popout: HTMLElement) => {
        contentToggleEdgeHandling(popout);
        lockBodyScroll();
      },
      (content: HTMLElement) => closeAllContentTogglesInToolbar(findToolbar(content)),
      (content: HTMLElement, button: HTMLButtonElement) => {
        const ariaExpanded = button.getAttribute('aria-expanded');
        const toolbar = findToolbar(content);
        if (ariaExpanded === 'true') {
          toolbar?.classList.add('toolbar--has-popout');
        } else {
          toolbar?.classList.remove('toolbar--has-popout');
        }

        content.addEventListener('click', (event: MouseEvent) => {
          if ((event.target as HTMLElement).classList.contains('toolbar__popout')
            && !isElementWithClassNameInEventPath(event as MouseEvent, 'toolbar__filter__content')) {
            closeAllContentTogglesInToolbar(toolbar);
            unlockBodyScroll();
          }
        });
      },
    );
  });

  window.addEventListener('load', () => {
    windowInnerWidth = window.innerWidth;
  });

  window.addEventListener('resize', () => {
    if (windowInnerWidth > 0 && window.innerWidth !== windowInnerWidth) {
      windowInnerWidth = window.innerWidth;
      navElements.forEach((n) => setResponsiveNav(navElements, n));
      setTabs(tabbedObjects);
    }
  });
});
